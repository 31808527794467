// Package Imports

// Project Imports
import ModuleIdentifierConst from 'core/common/moduleIdentifier';
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const icon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_304_2899)">
      <path
        d="M13.6648 1.99989C13.8068 2.02833 13.9773 2.08523 14.1193 2.11368C14.8011 2.34128 15.3125 2.79647 15.5966 3.45081C15.6534 3.59306 15.7386 3.64996 15.9091 3.64996C17.8125 3.67841 19.4034 4.98708 19.858 6.75095C19.9432 7.09235 20 7.43374 20 7.77513C20 11.1606 20 14.5177 20 17.9032C20 19.9515 18.4091 21.7154 16.392 21.9714C16.1932 21.9999 16.0227 21.9999 15.8239 21.9999C13.608 21.9999 11.4205 21.9999 9.20455 21.9999C7.13068 21.9999 5.42614 20.549 5.08523 18.5006C5.02841 18.3299 5 18.1023 5 17.9032C5 14.5177 5 11.1606 5 7.77513C5 5.75522 6.53409 4.0198 8.52273 3.70686C8.75 3.67841 8.97727 3.67841 9.17614 3.64996C9.23295 3.64996 9.31818 3.59306 9.31818 3.53616C9.6875 2.68267 10.3409 2.17058 11.25 1.99989C11.2784 1.99989 11.2784 1.97144 11.3068 1.97144C12.1023 1.99989 12.8693 1.99989 13.6648 1.99989ZM18.3239 12.8391C18.3239 11.1606 18.3239 9.4821 18.3239 7.80358C18.3239 6.69405 17.5852 5.69832 16.5057 5.44228C16.0795 5.32848 15.5966 5.38538 15.142 5.32848C14.9716 5.30003 14.8011 5.30003 14.6307 5.24313C14.3182 5.10088 14.1761 4.84484 14.1477 4.50344C14.0909 3.93445 13.7784 3.64996 13.2102 3.64996C12.7273 3.64996 12.2159 3.64996 11.733 3.64996C11.1932 3.64996 10.8807 3.93445 10.8239 4.47499C10.7955 4.61724 10.7955 4.75949 10.7386 4.87329C10.5682 5.18623 10.2841 5.32848 9.94318 5.32848C9.54545 5.32848 9.14773 5.30003 8.75 5.35693C7.5 5.58452 6.67614 6.58026 6.67614 7.86048C6.67614 11.1606 6.67614 14.4608 6.67614 17.7609C6.67614 19.2118 7.75568 20.2929 9.20455 20.2929C11.392 20.2929 13.5795 20.2929 15.767 20.2929C15.9091 20.2929 16.0511 20.2929 16.1648 20.2645C17.4716 20.0369 18.2955 19.0411 18.2955 17.704C18.3239 16.1108 18.3239 14.4892 18.3239 12.8391Z"
        fill="currentColor"
      />
      <path
        d="M14.1476 12.0142C14.6873 12.0142 15.2555 12.0142 15.7953 12.0142C16.2782 12.0142 16.6191 12.3556 16.6476 12.8392C16.6476 13.2944 16.3066 13.6927 15.8237 13.6927C14.6873 13.6927 13.5794 13.6927 12.443 13.6927C11.9601 13.6927 11.6191 13.2944 11.6191 12.8392C11.6191 12.3556 11.9885 12.0142 12.4714 12.0142C13.068 12.0142 13.6078 12.0142 14.1476 12.0142Z"
        fill="currentColor"
      />
      <path
        d="M14.1475 16.9927C13.6077 16.9927 13.0679 16.9927 12.4997 16.9927C12.0168 16.9927 11.6475 16.6513 11.6475 16.1677C11.6475 15.6841 12.0168 15.3142 12.4997 15.3142C13.6077 15.3142 14.6872 15.3142 15.7952 15.3142C16.2781 15.3142 16.6759 15.7125 16.6475 16.1677C16.6475 16.6229 16.2781 16.9927 15.7952 16.9927C15.2554 17.0212 14.7156 16.9927 14.1475 16.9927Z"
        fill="currentColor"
      />
      <path
        d="M14.1481 10.3355C13.6083 10.3355 13.0401 10.3355 12.5004 10.3355C12.1594 10.3355 11.8754 10.1648 11.7333 9.85186C11.5913 9.56737 11.6197 9.25442 11.8185 8.99838C11.989 8.77078 12.2163 8.65698 12.5004 8.65698C12.8129 8.65698 13.0969 8.65698 13.4094 8.65698C14.1765 8.65698 14.9719 8.65698 15.739 8.65698C16.1651 8.65698 16.4776 8.85613 16.5913 9.22597C16.7617 9.73806 16.4208 10.2502 15.881 10.3355C15.8526 10.3355 15.8242 10.3355 15.7958 10.3355C15.256 10.3355 14.7163 10.3355 14.1481 10.3355Z"
        fill="currentColor"
      />
      <path
        d="M10.0004 12.8391C10.0004 13.2943 9.63104 13.6641 9.17649 13.6641C8.72195 13.6641 8.32422 13.2658 8.32422 12.8106C8.32422 12.3554 8.69354 11.9856 9.17649 11.9856C9.63104 12.014 10.0004 12.3839 10.0004 12.8391Z"
        fill="currentColor"
      />
      <path
        d="M10.0004 16.1677C10.0004 16.6229 9.63104 16.9927 9.17649 16.9927C8.72195 16.9927 8.32422 16.6229 8.32422 16.1392C8.32422 15.6841 8.72195 15.3142 9.14808 15.3142C9.63104 15.3427 10.0004 15.7125 10.0004 16.1677Z"
        fill="currentColor"
      />
      <path
        d="M10.0004 9.51065C10.0004 9.96585 9.63104 10.3357 9.17649 10.3357C8.72195 10.3357 8.32422 9.96585 8.32422 9.51065C8.32422 9.05546 8.69354 8.68562 9.14808 8.68562C9.63104 8.65717 10.0004 9.02701 10.0004 9.51065Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_304_2899">
        <rect
          width="15"
          height="20"
          fill="currentColor"
          transform="translate(5 2)"
        />
      </clipPath>
    </defs>
  </svg>
);

const ProgramResource: ResourceItem = {
  name: resourceName.program,
  identifier: ModuleIdentifierConst.PROGRAM,
  list: `${resourceName.program}`,
  create: `${resourceName.program}/create/:id`,
  edit: `${resourceName.program}/edit/:id`,
  show: `${resourceName.program}/show/:id`,
  options: { label: 'Program', icon },
};

export default ProgramResource;
