// eslint-disable-next-line import/named
// Package Imports
import { MetaQuery } from '@refinedev/core';

// Project Imports
import PaginationPara from 'core/constants/PaginationPara';
import logger from 'core/utils/logger';
import axiosInstance from 'providers/axiosInstance/axiosInstance';

type PaginationProps = {
  current?: number;
  pageSize?: number;
  mode?: string;
};

type FilterProps = {
  field?: number | string;
  operator?: number | string;
  value: number | string;
};

type SortProps = {
  field?: number | string;
  order?: number | string;
};

type GetListProps = {
  apiUrl: string;
  resource: string;
  pagination: PaginationProps;
  sort?: SortProps[];
  filters?: FilterProps[];
  meta?: MetaQuery;
};

const getList = async ({
  apiUrl,
  resource,
  pagination,
  sort,
  filters,
  meta,
}: GetListProps) => {
  logger('meta', meta);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const customFilter = meta?.customfilter ?? null;
  const newFilter = filters ?? [];
  let url;
  if (resource.includes('?')) {
    url = `${apiUrl}/${resource}`;
  } else {
    url = `${apiUrl}/${resource}/`;
  }

  if (pagination.mode !== 'off') {
    const {
      current = PaginationPara.pageNo,
      pageSize = PaginationPara.pageSize,
    } = pagination ?? {};
    // url += `?page=${current}&page_size=${pageSize}`;
    if (resource.includes('?')) {
      url += `&page=${current}&page_size=${pageSize}`;
    } else {
      url += `?page=${current}&page_size=${pageSize}`;
    }
  }

  if (sort && sort?.length > 0) {
    const { field, order } = sort[0] ?? {};
    url += `${pagination.mode === 'server' ? '&' : '?'}ordering=${
      order === 'desc' ? '-' : ''
    }${field}`;
  }

  if (newFilter.filter((x) => x.value !== '').length > 0) {
    newFilter.forEach((filter) => {
      const { field, operator, value } = filter ?? {};
      if (value !== 'undefined') {
        url += `${url.includes('?') ? '&' : '?'}${field}__${
          operator === 'contains' ? 'icontains' : operator
        }=${value}`;
      }
    });
  }

  if (customFilter !== null && customFilter?.selfRead === true) {
    url += `&userName=${customFilter.username}`;
  }

  const response = await axiosInstance.get(url);
  const { data, headers } = response;

  const total = headers ? Number(headers['x-total-count']) : 0;
  if (meta?.type === 'select') {
    const { results } = data;
    return { data: results };
  }

  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

    data,
    total,
  };
};

export default getList;
